var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card",
            { staticClass: "mb-0 pb-0" },
            [
              _c(
                "tabs-component",
                { attrs: { pills: "" } },
                [
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Detail ")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("DetailCC", {
                        on: { save: _vm.save },
                        model: {
                          value: _vm.userData,
                          callback: function ($$v) {
                            _vm.userData = $$v
                          },
                          expression: "userData",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: { disabled: !_vm.allowed },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Practice List ")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [_c("PracticeListCC")],
                    1
                  ),
                  _c("b-tab", {
                    attrs: { disabled: !_vm.allowed },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [_vm._v(" Activity & Notes ")]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }