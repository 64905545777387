/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ColumnFilter,
    GeneralStatus,
    PracticesDto,
    PracticesDtoListResponseModel,
    PracticesDtoModelResponse,
    SortType,
} from "./data-contracts";
import {ContentType, HttpClient, RequestParams} from "./http-client";

export class Practices<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Practices
     * @name LoadList
     * @request GET:/Practices/Load
     */
    loadList = (
        query?: {
            EhrId?: number;
            UserId?: number;
            Take?: number;
            Skip?: number;
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            PracticeId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<PracticesDtoListResponseModel, any>({
            path: `/Practices/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Practices
     * @name UploadLogoCreate
     * @request POST:/Practices/UploadLogo
     */
    uploadLogoCreate = (
        data: {
            PracticeId: number
            File: File
        },
        params: RequestParams = {},
    ) =>
        this.request<PracticesDtoModelResponse, any>({
            path: `/Practices/UploadLogo`,
            method: "POST",
            body: data,
            type: ContentType.FormData,
            format: "json",
            ...params,
        });

    /**
     * No description
     *
     * @tags Practices
     * @name GetLogo
     * @request POST:/Practices/GetLogo
     */
    GetLogoUri = (practiceId?: number) => {
        return `${this.instance.defaults.baseURL}Practices/GetLogo?PracticeId=${practiceId}`
    };

    /**
     * No description
     *
     * @tags Practices
     * @name DeleteLogoDelete
     * @request DELETE:/Practices/DeleteLogo
     */
    deleteLogoDelete = (query?: { practiceId?: number }, params: RequestParams = {}) =>
        this.request<PracticesDtoModelResponse, any>({
            path: `/Practices/DeleteLogo`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Practices
     * @name PostPractices
     * @request POST:/Practices/Add
     */
    postPractices = (data: PracticesDto, params: RequestParams = {}) =>
        this.request<PracticesDtoModelResponse, any>({
            path: `/Practices/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Practices
     * @name GetByIdList
     * @request GET:/Practices/GetById
     */
    getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<PracticesDtoModelResponse, any>({
            path: `/Practices/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Practices
     * @name DeleteDelete
     * @request DELETE:/Practices/Delete
     */
    deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<PracticesDtoModelResponse, any>({
            path: `/Practices/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Practices
     * @name UpdateCreate
     * @request POST:/Practices/Update
     */
    updateCreate = (data: PracticesDto, params: RequestParams = {}) =>
        this.request<PracticesDtoModelResponse, any>({
            path: `/Practices/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
