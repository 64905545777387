var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      value: _vm.listData.map(function (x) {
        return x[_vm.sourceIdField]
      }),
      options: _vm.optionsCopy,
      label: _vm.label,
      reduce: function (x) {
        return x.id
      },
      selectable: function (x) {
        return x.status == "Active"
      },
      multiple: "",
      required: _vm.required,
      placeholder: "Enter ...",
    },
    on: {
      "option:selecting": _vm.optionSelected,
      "option:deselected": _vm.optionDeselected,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }